<template>
  <div class="custom-dropdown" @click="toggleDropdown" :class="{disabled: isReadOnly}">
    <div class="selected-item" :class="{disabled: isReadOnly}">{{ selectedLabel }}</div>
    <div v-if="isOpen" class="dropdown-menu">
      <div v-for="option in options" :key="option.value" class="dropdown-item" @click="selectOption(option, $event)">
        {{ option.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: [String, Boolean],
      default: "",
    },
    processDetailInfo: {
      type: Object,
      default: () => {},
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isOpen: false,
      selectedLabel: this.getInitialLabel(),
    };
  },
  methods: {
    toggleDropdown() {
      if(this.isDisablePulldown) return;
      this.isOpen = !this.isOpen;
    },
    selectOption(option, event) {
      event.stopPropagation();
      this.$emit("input", option.value);
      this.$emit("getDataPulldown", option.value)
      this.selectedLabel = option.label;
      this.isOpen = false;
    },
    handleDocumentClick(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
      }
    },
    getInitialLabel() {
      const selectedOption = this.options.find(
        (option) => option.value === this.value
      );
      return selectedOption ? selectedOption.label : "Select an option";
    },
  },
  watch: {
    value(newValue) {
      this.selectedLabel = this.getInitialLabel();
    },
  },
  mounted() {
    document.addEventListener("click", this.handleDocumentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleDocumentClick);
  },
};
</script>

<style lang="scss" scoped>
.custom-dropdown {
  position: relative;
  display: flex;
  height: 28px;
  padding-left: 10px;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--Devider-Black, rgba(42, 42, 48, 0.1));
  background: $monoOffWhite;
  &.disabled {
    pointer-events: none;
    cursor: default;
    color: $monoMid;
    background: $monoLight;
  }
  .selected-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    color: $monoBlack;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.33px;
    &::after {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      background-image: url('./../../assets/icons/pulldown/selected-box.svg');
      background-size: contain;
      background-repeat: no-repeat;
      margin: 8px;
    }
    &.disabled {
      color: $monoMid;
      font-weight: 500;
    }
  }
  .dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    width: max-content;
    border: 1px solid $monoDarkWhite;
    z-index: 1000;
    background: $monoOffWhite;
    box-shadow: 0 1.2365612984px 1.1335145235px 0 rgba(160, 181, 186, 0.24),
      0 3.1273565292px 2.8667433262px 0 rgba(160, 181, 186, 0.17),
      0 6.3795137405px 5.847887516px 0 rgba(160, 181, 186, 0.13),
      0 13.1405925751px 12.045542717px 0 rgba(160, 181, 186, 0.11),
      0 36px 33px 0 rgba(160, 181, 186, 0.07);
    border-radius: 4px;
    .dropdown-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 7px 16px 9px;
      gap: 4px;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: $monoBlack;
      border-bottom: 1px solid rgba(42, 42, 48, 0.1);
      cursor: pointer;
      &:hover {
        background-color: $monoOnWhite;
      }
    }
  }
}
</style>
